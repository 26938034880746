h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

h2 {
    font-size: 1.75em;
    margin: 0.75em 0;
}

h3 {
    font-size: 1.5em;
    margin: 0.83em 0;
}

h4 {
    font-size: 1.25em;
    margin: 1.12em 0;
}

h5 {
    font-size: 1em;
    margin: 1.5em 0;
}

h6 {
    font-size: 0.75em;
    margin: 1.67em 0;
}