@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.font-ds-400 {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.font-ds-500 {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.font-ds-600 {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.font-ds-700 {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}